@tailwind base;
@tailwind components;
@tailwind utilities;
@import "flowbite";

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap'); */


@layer base {
    html {
        font-family: inter, system-ui, sans-serif;
    }
}

/* global.css */

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.animate-spin {
    animation: spin 1s linear infinite;
}

html {
    scroll-behavior: smooth;
}


.swiper-pagination {
    position: absolute;
    bottom: -4px !important; /* Place la pagination plus bas */
    gap: 8px;
}


/* Bullets standards */
.swiper-pagination-bullet {
    background-color: #d9d9d9; /* Couleur par défaut */
    width: 12px;
    height: 12px;
    opacity: 0.7;
    border-radius: 50%; /* Assure des points ronds */
    transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Bullet actif */
.swiper-pagination-bullet-active {
    background-color: #5f62e2; /* Couleur du point actif */
    opacity: 1;
    transform: scale(1.3); /* Agrandit le point actif */
}

/* Flèches de navigation */
.swiper-button-next,
.swiper-button-prev {
    color: #5f62e2; /* Couleur des flèches */
}

/* Agrandit le Swiper en Y */
.swiper-height {
    height: 400px; /* Ajuste cette valeur selon ton besoin */
}

.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; /* Prend toute la hauteur du Swiper */
}